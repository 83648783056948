
export const MenuItems = [
 {
    Title: "Run",
    url: "/",
    cName: "nav-links"
  },
  {
    Title: "Results",
    url: "/results",
    cName: "nav-links"
  },
  {
    Title: "Logout",
    url: "/logout",
    cName: "nav-links-mobile"
  }
];
