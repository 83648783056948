import './GetGroups.css';
import Group from './Group';
import React, { useState, useEffect } from 'react';


export default function GetGroups({groups,checkedItems,setCheckedItems}){


    return (
        <div>
              <div className="accordion">
                <div className="accordion-header">
                <div className='checkbox-wrapper'> Groups Files
                <div>
                {groups.map((item, index) => (
                  <Group key={index} groupname={item.name} index={item.name} checkedItems={checkedItems} setCheckedItems={ setCheckedItems} />
                ))}
                </div>
                
                </div>
                </div>
              </div>
          </div>
    ) ;
}