import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GetData from '../components/GetData';

const  FilesPage = () => {
    return (
    <GetData/> 
  )
};

export default FilesPage;
