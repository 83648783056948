import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DarkTable from '../components/DisplayTableProcesses';
import { useAuth } from "../provider/authProvider";

const  Results = () => {
    const url = 'https://api.thecrossproduct.xyz/v1/app/processes';
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth(); // Obtenez le token depuis le contexte

    useEffect(() => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const body={"items_per_page" : 10000}
      
      axios.post(url,body)
        .then(response => {
          if(response.data){
          const responseData = response.data.processes;
          console.log(responseData);
          setData(responseData); // Mettez les données dans l'état du composant
          }
          else{setData([])}
          setLoading(false);
        })
        .catch(error => {
          // Gestion des erreurs
        });
    }, [])

    return (<>
    <div align='center' style={{color:"white"}}>
    <h1 >Results</h1>
      { (!data)||loading ? 
      (<p>...</p>):(
        <div>
        <DarkTable data={data}/>
        </div>
      )
    }{ (data)||loading ? 
      (<p></p>):(<p>No process</p>)
    }
      </div>
	    </>
  )
};

export default Results;

