import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

const  Logout = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();

  const handleLogout = () => {
    setToken("");
    navigate("/login", { replace: true });
  };

  return handleLogout()
};

export default Logout;
