import React, { useState, useEffect } from 'react';
import axios from 'axios';
import File from './File';
import { useAuth } from "../provider/authProvider";
import "./Group.css"

export default function Dir({groupname, dir, prefix, index, checkedItems, setCheckedItems}){
    
    // useAuth
    const { token } = useAuth(); 
    // Toggle
    const [isOpen, setIsOpen] = useState(false);
    // Request
    const [isLoading, setLoading] = useState(false);
    //Store Data from request
    const [sdirs, setSDirs] = useState([]);
    const [sfiles, setSFiles] = useState([]);
    const [error, setError] = useState(null);
    const [next,setNext] = useState(null);

    var body={}
  
    if(groupname ===""){
      body = {
        hierarchy: true,
        items_per_page : 10000,
        prefix: [prefix]
      }
    }else{
      body = {
        groups: [groupname],
        hierarchy: true,
        items_per_page : 10000,
        prefix: [prefix]
      }
    }

    useEffect(() => {
      if (isLoading) {
       axios.defaults.headers.common["Authorization"] = "Bearer " + token;
       axios.post('https://api.thecrossproduct.xyz/v1/data',JSON.stringify(body), {headers: {
        'Content-Type': 'application/json'
        }})
       .then((response) => {
          if (response.status === 200) {
              if ('dirs' in response.data) {
                const sdirs = response.data.dirs
                const root = sdirs.shift();
                for (var i = 0; i < sdirs.length; i++) {
                  var segments = sdirs[i].split("/");
                  sdirs[i] = segments[segments.length - 2];  
                }
                setSDirs(sdirs)      
              } else {
                setSDirs([]) ; 
              }
        
              if ('files' in response.data) {
              setSFiles(response.data.files) ; 
              } else {
                setSFiles([]) ; 
              }
              
          } else {
          console.log('Erreur de requête. Statut :', response.status);
          }
          setLoading(false);  
  })
  .catch(error => {
    console.error('Une erreur s\'est produite :', error);
    setLoading(false);
  });
    }
  }, [isLoading]);

  const toggleAccordion = (event) => {
    event.stopPropagation();
    setLoading(true);
    setError(null);
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (item,event) => {
    event.stopPropagation();
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };
      if(showcheck(item)){
        updatedCheckedItems[item]=false;
        for (let other in checkedItems){
          if (other.startsWith(item)) {
            delete updatedCheckedItems[other];
          }}
        return updatedCheckedItems;}
      else{
          updatedCheckedItems[item]=true;
          for (let other in checkedItems){
            if (other.startsWith(item) ){
              updatedCheckedItems[other]=true;
            }}
          return updatedCheckedItems;}
    });
  };

 const showcheck = (name) => {
  var isin =false;
  if (!(name in checkedItems)){
    var chemin = name;
    const partiesDuChemin = chemin.split('/');
    let cheminActuel = '';
     for (let i = 0; i < partiesDuChemin.length ; i++) {
        cheminActuel += partiesDuChemin[i] + '/';
        if (cheminActuel in checkedItems && checkedItems[cheminActuel]==true) {
          isin=true
        }
        if (cheminActuel in checkedItems && checkedItems[cheminActuel]==false) {
          isin=false
        }
      }
      for (let item in checkedItems) {
        if (item.startsWith(name) && checkedItems[item]==false) {
          isin=false
        }
      }     
    }else{
      for (let item in checkedItems){
        if ((item.startsWith(name)) && checkedItems[item]==false){
          isin = false;
          return isin
        }else{
      isin = checkedItems[name]
        }
      }
    }
 return isin
}

 function filename (groupname,prefix){
  if (groupname === "" ){return prefix}
  else {return groupname+'@'+prefix}
 }

  return (
    <div className="accordion">
      <div className="accordion-header" disabled={isLoading} onClick ={!isLoading ? toggleAccordion : null}>
        <span className={`icon ${isOpen ? 'open' : ''}`}  >{isOpen ? '-' : '+'}</span>
        <div className='checkbox-wrapper'>
          {dir}
          <input className='check-box'
            type="checkbox"
            checked={showcheck(filename (groupname,prefix)) || false}
            onChange={()=>{}}
            onClick={(event) => handleCheckboxChange(filename (groupname,prefix),event)}
            
          />  
        </div>
      </div>
      {isOpen &&
      <div>
      <div className="accordion-content"> {
          <div>
            <div className='directories'>
          {
              sdirs.map((item, index) => (
              <Dir key={index} groupname={groupname} dir={item} prefix={prefix+item+'/'} index={index} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
              ))
            }
          </div>
          <div className='Files'> 
          {
              sfiles.map((item, index) => (
              <File key={index} name={item} index={index} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
              ))
            }
          </div>
          </div>
         }
       </div>
      </div>
      }
    </div>
  );
};
