import axios from 'axios';
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useAuth } from "../provider/authProvider";
import Button from 'react-bootstrap/Button';
import JSZip from "jszip";
import { saveAs } from "file-saver";


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #333;
  color: #fff;
  
`;

const Th = styled.th`
  padding: 8px;
  text-align: left;
  background-color: #555;
`;

const Td = styled.td`
  padding: 8px;
  border-bottom: 1px solid #444;
`;


export default function DarkTable({ data }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Process id',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'tags',
      },
      {
        Header: 'TCP - Solution',
        accessor: 'app',
      },
      {
        Header: 'Created',
        accessor: 'launched',
      },
      {
        Header: 'Status',
        accessor: 'state',
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'Created',
            desc: false
          }
        ]
      }
    },
    useSortBy
  );

  const { token } = useAuth();

  const handleRowClick = (row) => {
    const TCPApp = "https://api.thecrossproduct.xyz/v1/app"
    const url = TCPApp + "/process/" + row.original.id;
    if (row.original.state === 'dead') {
      alert("Vos fichiers vont être téléchargés")
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.get(url)
        .then(response => {
          axios.get(TCPApp + "/process/outputs/" + row.original.id)
            .then(response => {
              if (response.data["outputs"]) {
                axios.get(TCPApp + "/process/outputs/" + row.original.id)
                  .then(response => {
                    if (response.data["outputs"]) {

                      const files = response.data.outputs

                      if (files.length < 10 && !files.map(file => file.key.replace('/', '')).join('').includes('/')) {
                        for (var i = 0; i < files.length; i++) {
                          const downloadUrl = files[i]['url']
                          if (downloadUrl) {
                            const link = document.createElement('a');
                            link.target = "_blank"
                            link.href = downloadUrl;
                            link.click();
                          }
                          else { alert(`This file has been deleted ${files[i]['key']}`) }
                        }
                      } else {
                        const zip = new JSZip();

                        const download = (item) => {

                          return fetch(item.url).then((resp) => {
                            zip.file(item.key, resp.blob());
                          });
                        };

                        const downloadAll = () => {
                          const arrOfFiles = files.map((item) => download(item)); //create array of promises
                          Promise.all(arrOfFiles)
                            .then(() => {
                              zip.generateAsync({ type: "blob" }).then(function (blob) {
                                saveAs(blob, row.original.id + ".zip");
                              });
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        };
                        downloadAll();
                      }

                    } else { alert("Outputs is empty"); }
                  })
              }
            })
        })
        .catch(error => {
          console.error(error);
        });

    }
  };


  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </Th>

            ))}
            <Th>Outputs</Th>
          </tr>


        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                );
              })}
              {<Td {...row.getRowProps()}

              > {row.original.state === 'dead' ? <Button onClick={() => handleRowClick(row)}>Download</Button> : ''}</Td>}
            </tr>
          );
        })}

      </tbody>
    </Table>
  );
};


export function DisplayTableProcesses(props) {
  return (
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>App</th>
          <th>Status</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        {props.array.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.app}</td>
            <td>{item.status}</td>
            <td>{item.launched}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
