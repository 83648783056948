import {useState} from 'react';
import "./Dir.css";


export default function File({name, index, checkedItems, setCheckedItems}){

  var segments = name.split("/");
  const filename = segments[segments.length - 1]; 


  const handleCheckboxChange = (item,event) => {
    event.stopPropagation();
    setCheckedItems((prevState) => {
      const updatedCheckedItems = { ...prevState };
      if(showcheck(item)){
        updatedCheckedItems[item]=false;
        for (let other in checkedItems){
          if (other.startsWith(item)) {
            delete updatedCheckedItems[other];
          }}
        return updatedCheckedItems;}
      else{
          updatedCheckedItems[item]=true;
          for (let other in checkedItems){
            if (other.startsWith(item) ){
              updatedCheckedItems[other]=true;
            }}
          return updatedCheckedItems;}
    });
  };

 const showcheck = (name) => {
  var isin =false;
  if (!(name in checkedItems)){
    var chemin = name;
    const partiesDuChemin = chemin.split('/');
    let cheminActuel = '';
     for (let i = 0; i < partiesDuChemin.length ; i++) {
        cheminActuel += partiesDuChemin[i] + '/';
        if (cheminActuel in checkedItems && checkedItems[cheminActuel]==true) {
          isin=true
        }
      }
      for (let item in checkedItems) {
        if (item.startsWith(name) && checkedItems[item]==false) {
          isin=false
        }
      }     
    }else{
      for (let item in checkedItems){
        if ((item.startsWith(name)) && checkedItems[item]==false){
          isin = false;
          return isin
        }else{
      isin = checkedItems[name]
        }
      }
    }
 return isin
}

    return (
    <div className='checkbox-wrapper'>
      {filename}
        <input className='check-box'
          type="checkbox"
          checked={showcheck(name) || false}
          onChange={()=>{}}
          onClick={(event) => handleCheckboxChange(name,event)}
        />  
    </div>
    );

}
