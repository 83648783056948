import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogin = (token) => {
    setToken(token);
    navigate("/", { replace: true });
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    // Effectuez l'appel à l'API ici, par exemple en utilisant la méthode fetch
    fetch('https://api.thecrossproduct.xyz/v1/auth/login', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(username + ':' + password)
      }
    })
    .then((response) => {
      if (response.status === 200) {
        return response.json(); // Conversion de la réponse en JSON
      } else if (response.status === 401){
        setError('Identifiants incorrects. Réessayez.');
        setLoading(false); 
         return "";
      } else {
        setError('Une erreur s\'est produite. Réessayez plus tard.');
        setLoading(false); 
        return "";
      }
    })
    .then((data) => {
      if (data != ""){
      const token = data.token;
      handleLogin(token)}
      setLoading(false);    
    });
  };


  return <>
   <div className="login-page">
      <div className="login-container">
        <div className="logo">
          <img src="https://s3.fr-par.scw.cloud/static.tcp/WhiteLogoBorder.png" alt="Logo de l'application" />
        </div>
        <form className="login-form" >
          <h2>Login</h2>
          <div className="form-group">
            <label htmlFor="username">Nom d'utilisateur</label>
            <input type="text" id="username" name="username" value={username} onChange={handleUsernameChange} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input type="password" id="password" name="password" value={password} onChange={handlePasswordChange} />
          </div>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={!isLoading ? handleFormSubmit : null}
          >
          {isLoading ? "Please wait ..." : "Se connecter"}
          </Button>
          {error && <p style={{color :'white'}}>{error}</p>}
        </form>
      </div>
    </div>
  </>;
};

export default Login;
