import { Navigate, useNavigate} from "react-router-dom";
import React, { useState , useEffect } from 'react';
import { useAuth } from "../provider/authProvider";
import SelectFiles from "../components/SelectFiles"
import RunApp from "../components/RunApp"
import  { LoadingButton_run,LoadingButton_files,LoadingButton_call } from '../components/LoadButton'
import { Container, Row, Col } from 'react-grid-system';
import GetData from "../components/GetData";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Next } from "react-bootstrap/esm/PageItem";

const style_FileSelector = {
    width: "100%",
    height: "100%",
    backgroundColor: "#444",
    borderRadius: "5px"
  }

const  ProductPage = () => {


  const [modeCS, setModeCS] = useState(false);
  const [name, setName] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate=useNavigate();
  const { token } = useAuth(); // Obtenez le token depuis le contexte
  const [allfiles,setAllfiles]=useState([]);
  const [nbfiles,setNbfiles]=useState(0);
 
  const [isLoadingV, setLoadingV] = useState(false);
  const [errorV, setErrorV] = useState();

  const updateFiles = (files) => {
    setAllfiles((prevState) =>{return prevState.concat(files)})
  }  

  const setStateCheckedItems = (e) => {
   setCheckedItems(e)
  }

  useEffect(() =>{
    if(allfiles){console.log("La longeur de allfiles est :",allfiles.length)
    console.log(allfiles)
    setNbfiles(allfiles.length)}
  },[allfiles])

  useEffect(()=>{
  //const handleValidate = () => {
    console.log("CheckedItem : ", checkedItems)
    var allgroups=[]
    var allprefix=[]
    for (var val in checkedItems){
      console.log("val",val)
      if(checkedItems[val]===true)
      {
        console.log("ici")
        console.log("val",val)
        if (val.includes('@')){
          allgroups.push(val.split('@')[0])
        allprefix.push(val.split('@')[1])}
        else{
          allprefix.push(val)}  
        
      }
    }
    allgroups=[...new Set(allgroups)]
    console.log(allgroups)
    console.log(allprefix)
    const body = 
    {
               items_per_page : 10000,
               prefix: allprefix
    }
   if (allgroups.length>0){body['groups']=allgroups}    

   console.log("groups :",body["groups"])
   console.log(body["prefix"])

   axios.defaults.headers.common["Authorization"] = "Bearer " + token;
   axios.post(
              'https://api.thecrossproduct.xyz/v1/data',JSON.stringify(body), {headers: {
              'Content-Type': 'application/json'}}
           )
       .then
           (
             (response) => 
             {
              console.log(response)
               if (response.status === 200) {
               console.log(response)   
               var allFiles= response.data.files
               for (var val in checkedItems){
                if(checkedItems[val]===false){
                  const index = allFiles.indexOf(val);
                  const nallFiles = allFiles.splice(index, 1);
                  console.log(allFiles)
                }
                
              } 
              console.log(allFiles)
              setAllfiles(allFiles)
               } else {
               console.log('Erreur de requête. Statut :', response.status);
               
               }
             }
           )
       .catch
           (  error => {
               console.error('Une erreur s\'est produite :', error);
               }
           );
  },[checkedItems])
 

  useEffect(() => {
    if (isLoading) {
    const body = {
        "inputs" : { "files" : allfiles },
        "output-prefix" : "output",
	      "pool":["scw:fr-par-1:ENT1-XL","scw:fr-par-1:ENT1-L","scw:fr-par-1:ENT1-M"],
        "mem":"1Tb",    
        "tags":[name]
    }
    console.log(allfiles)
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    var url="https://api.thecrossproduct.xyz/v1/app/quality_check/first_check/run"
    if(modeCS){url="https://api.thecrossproduct.xyz/v1/app/quality_check/cross_sections/run"}

    axios.post(url,JSON.stringify(body), {headers: {
          'Content-Type': 'application/json'
        }})
      .then((response) => {
        if (response.status === 200) {
      console.log('La requête a réussi !');
      setLoading(false);
      navigate('/results')
      console.log('Données reçues :', response.data);
    } else {
      setError('Erreur de requête. Statut :', response.status);
    }
  })
  .catch(error => { 
    setError('Une erreur est survenue, vérifier que la liste des fichiers contient uniquement des fichier .las, .laz ou .e57 ');
    setLoading(false);
  });
    }
  }, [isLoading]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleClear= () => {
    setStateCheckedItems({})
  };
 
  const handleToggleMode = () => {
    setModeCS(!modeCS);
  };

  const handleLaunchRun= () => {
    if (Object.keys(checkedItems)<1){
    setError("Veuillez selectionner des fichiers")
    console.log("Pas de Fichiers")
    }else{
    setLoading(true)
    }
  };
 
  return <>
  <Container fluid >
  <Row style={{align:"top"}}>
  <Col sm={8} >
        <Container className="FilesSelector" style={style_FileSelector} > 
        <Row style={{color:"white", padding:"10px"}}><div>Sélectionner vos fichier à traiter</div></Row>   
        <Row style={{color:"black", background:"white"}}> 
        <Row style={{margin : "0px",width:"100%"}}><GetData checkedItems={checkedItems} setCheckedItems={setStateCheckedItems}/></Row>
        </Row>
        </Container>
    </Col>
    <Col sm={4} >
        <Container className="FilesSelector" style={style_FileSelector} > 
        <Row style={{color:"white", padding:"10px"}}><div>Fichiers selectionnés</div></Row>   
        <Row style={{color:"black", background:"white"}}> 
        {! (Object.keys(checkedItems)<1) ? 
          <ul>{Object.keys(checkedItems).map((item) => (<li>{checkedItems[item] ? item : <del>{item}</del>}</li>))}</ul>
          : <div style={{padding:"10px",borderRadius: "10px"}}>Aucun fichiers selectionnés </div>}
        </Row>
        <Row style={{color:"black", background:"white"}}> 
        {(Object.keys(checkedItems)<1) ? 
          <></> : <div style={{padding:"10px",borderRadius: "10px"}}>{nbfiles} fichiers sélectionnés </div>}
        </Row>
        <Row style={{color:"white",justifyContent:"center"}} >
            <button className="Button-Clear" style={{backgroundColor:"#911"}} onClick={handleClear}>
              Effacer la selection
            </button>
        </Row>  
        <Row style={{ display: 'flex', justifyContent: 'center' ,padding:"20px", marginTop: '20px' }}>
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Nom du process"
              style={{ width: '90%', textAlign: 'center' }}
            />
        </Row> 
        <Row style={{ justifyContent: 'center', marginTop: '20px' }}>
           <select value={modeCS ? 'Cross-Section' : 'Rapport'} onChange={handleToggleMode}>
              <option value="Cross-Section">Cross-Section</option>
              <option value="Rapport">Rapport</option>
            </select>
        </Row>

        <Row style={{color:"white",padding:"20px"}}>
            <Button 
                disabled={isLoading}
                onClick={!isLoading ? handleLaunchRun : null}>
                <h3>{isLoading ? "Please wait ..." : "Lancer"}</h3>
            </Button> 
            <div style={{color:"white", padding:"10px", justifyContent:"center"}}> { !error ?  "": error}</div>
        </Row>   
        </Container>
    </Col>
  </Row>
  </Container>
  </>;
};

export default ProductPage;


