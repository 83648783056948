import { Container, Row, Col } from 'react-grid-system';
export default function Test(){
return(<>
    <div style={{backgroundColor:'red', display:'flex',width:"100%",heigth:"100%"}}>
    Col 1
    </div>
    <Container style={{backgroundColor:'red', margin:"0",width:"120%"}}>
    <Col style={{backgroundColor:'red', display:'flex',width:"100%",heigth:"100%"}}>
    Col 1
    </Col>
    <Col style={{backgroundColor:'blue', display:'flex',width:"100%",heigth:"100%"}}>
    Col 2
    </Col>
    <Row>
    <Col style={{backgroundColor:'red', display:'flex',width:"100%",heigth:"100%"}}>
    Col 3
    </Col>
    <Col style={{backgroundColor:'blue'}}>
    Col 4
    </Col>
    </Row>

    </Container>
    </>
)

}