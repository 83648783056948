/*import './GetGroups.css';*/
import Group from './Group';
import React, { useState, useEffect } from 'react';


export default function GetUserFiles({checkedItems,setCheckedItems}){

    return (
        <div>
              <div className="accordion">
                <div className="accordion-header">
                <div className='checkbox-wrapper'> Personal Files
                <div>
                 {<Group groupname="" index="userfiles" checkedItems={checkedItems} setCheckedItems={ setCheckedItems} />}
                </div>
                </div>
                </div>
              </div>
          </div>
    ) ;
}