import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from "../provider/authProvider";
import GetGroups from './GetGroups';
import GetUserFiles from './GetUserFiles';
/*import './GetData.css'*/
import Group from './Group';
import Button from 'react-bootstrap/Button';

export default function GetData({checkedItems, setCheckedItems}) {

  const url="https://api.thecrossproduct.xyz/v1/auth"
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth(); // Obtenez le token depuis le contexte
  
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.get(url)
    .then(response => {
       const responseData = response.data.group;
       console.log(responseData);
       setData(responseData); // Mettez les données dans l'état du composant
       setLoading(false);
     })
     .catch(error => {
       // Gestion des erreurs
     });
   },[]
  )


  useEffect(() => {
   console.log(checkedItems)
   },[checkedItems]
  )


  const handleClear= () => {
    setCheckedItems({})
  };


  return(
   <>
    <div style={{width: "100%"}}>
      {loading ? (
        <p>Chargement en cours...</p>
      ) : (
        <>
        <div>
        <GetUserFiles checkedItems={checkedItems} setCheckedItems={ setCheckedItems} />
        </div>
        <div>
        <GetGroups groups={data} checkedItems={checkedItems} setCheckedItems={ setCheckedItems}/>
        </div>
        </>
      )}
    </div>
   </>
  )

}
