import React from 'react';
import {Link} from 'react-router-dom';
import {MenuItems} from './MenuItems';
import './NavBar.css'

export default function Navbar(){
    return (    
        <div className="Navbar" >
             <div className="Navbar-logo-container"> 
                  <img 
                      src="https://s3.fr-par.scw.cloud/static.tcp/Total-Logo-500x281-v2.png"
                      className="logo img-fluid"
                      alt="Logo Total energie"
                      width="300" 
                  /> 
        </div>

        <div className="Navbar-logo">

        <div className="Navbar-logotcp-container"> 
            <a href="https://www.thecrossproduct.com" target="_blank" >
                <img 
                      src="https://s3.fr-par.scw.cloud/static.tcp/WhiteLogoBorder.png"
                      className="logo img-fluid"
                      alt="Logo TheCrossProduct"
                      width="100" 
                 />
            </a>
        </div>
        <div className="Navbar-menu-icon"></div>
        <ul>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.url} className={item.cName}>{item.Title}</Link>
              </li>
            );
          })}
        </ul>
        </div>
        </div>        
    )

}