
import './Group.css'
import Dir from './Dir';
import File from './File';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from "../provider/authProvider";

export default function Group({ groupname, ...props }) {

  // useAuth
  const { token } = useAuth();

  // Toggle
  const [isOpen, setIsOpen] = useState(false);

  // Request
  const [isLoading, setLoading] = useState(false);

  //Store Data from request
  const [dirs, setDirs] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  var body = {}
  var dirname = "";

  if (groupname === "") {
    body = {
      hierarchy: true,
      items_per_page: 20000
    }
    dirname = "/";
  } else {
    body = {
      groups: [groupname],
      personal: false,
      hierarchy: true,
      items_per_page: 20000
    }
    dirname = groupname;
  }

  useEffect(() => {
    if (isLoading) {
      console.log(groupname)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.post('https://api.thecrossproduct.xyz/v1/data', JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.status === 200) {
            console.log('La requête a réussi !');
            setLoading(false);
            console.log(body)
            console.log('Données reçues :', response.data);
            const dirs = response.data.dirs
            const root = dirs
            console.log('Données dirs :', dirs);

            for (var i = 0; i < dirs.length; i++) {
              var segments = dirs[i].split("@");
              dirs[i] = segments[segments.length - 1];
            }
            setDirs(dirs)
            setFiles([response.data.files])
            console.log(dirs)
            console.log(files)
          } else {
            console.log('Erreur de requête. Statut :', response.status);
          }
        })
        .catch(error => {
          console.error('Une erreur s\'est produite :', error);
          setLoading(false);
        });
    }
  }, [isLoading]);


  const toggleAccordion = (event) => {
    event.stopPropagation();
    setLoading(true);
    setError(null);
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (item, event) => {
    event.stopPropagation();
    props.setCheckedItems((prevState) => {
      event.stopPropagation();
      const updatedCheckedItems = { ...prevState };
      updatedCheckedItems[item] = !updatedCheckedItems[item];
      return updatedCheckedItems;
    });
  };

  return (
    <div className="accordion">
      <div className="accordion-header" disabled={isLoading} onClick={!isLoading ? toggleAccordion : null}>
        <span className={`icon ${isOpen ? 'open' : ''}`}  >{isOpen ? '-' : '+'}</span>
        <div className='checkbox-wrapper'>
          {dirname}

        </div>
      </div>
      {isOpen &&
        <div>
          <div className="accordion-content"> {
            <div>
              {
                dirs.map((dir, index) => (
                  <Dir key={index} groupname={groupname} dir={dir} prefix={dir} index={index} checkedItems={props.checkedItems} setCheckedItems={props.setCheckedItems} />
                ))
              }
            </div>
          }
          </div>

          <div className="accordion-content"> {
            <div>
              {
                files.length > 0
                  ? files.map((item, index) => {
                    <File key={index} name={item} index={index} checkedItems={props.checkedItems} setCheckedItems={props.setCheckedItems} />
                  })
                  : <div></div>
              }
            </div>
          }
          </div>
        </div>
      }
    </div>


  );
};
